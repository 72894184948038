import axios from '@/plugins/axios.js';

const url = `/Users`;

export default {
    getAllUsers() {        
        return axios.get(url);
    },
    
    getUserById(userId) {
        const url = `${url}/${userId}`;
        return axios.get(url);    
    }
}
