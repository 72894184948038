import axios from '@/plugins/axios.js';

export default {
    getRefreshTokens() {
      const url = `/RefreshTokens`;
      return axios.get(url);
    },
    
    deleteRefreshToken(tokenId) {
      const url = `/RefreshTokens/${tokenId}`;
      return axios.delete(url);
    }

}

  