import axios from '@/plugins/axios.js';
import moment from "moment";

export default {
    getBudgetCategories() {
        console.log("Retrieving BudgetCategories");
        return axios.get('/BudgetCategories');
    },
    getSpendingLimits(date) {
        if (!date) {
            date = moment().toDate().toISOString();
        }
        const url = `/BudgetCategorySpendLimits?date=${date}`;
    
        return axios.get(url);
    },
    editBudgetCategory(model) {
        const url = `/budgetCategories`;
        return axios.put(url, model);
    }
}
