import axios from '@/plugins/axios.js';

export default {
    getUserRoles(userId) {
        const url = `/UserRole/User/${userId}`;
        return axios.get(url);
    },
    updateUserRoles(userId, userRoles) {
        const url = `/UserRole/User/${userId}`;
        return axios.post(url, userRoles);
    }
}