<template>
  <v-sheet width="100%" class="d-flex flex-wrap pa-4">
    <v-card tile class="px-4 py-2">
        <v-card-title>Broadcast Message</v-card-title>
        <v-card-subtitle>Send popup notification to all logged in users.</v-card-subtitle>

        <v-card-text>
            <v-form @submit.prevent="sendTestMessage()">
            <v-text-field v-model="message" label="Message"></v-text-field>
            <v-btn type="submit" block class="mt-2" color="primary">Send</v-btn>
            </v-form>
        </v-card-text>
    </v-card>

    <v-card tile class="px-4 py-2">
        <v-card-title>SignalR Connection</v-card-title>
        <v-card-subtitle>Connect and disconnect from SignalR web socket.</v-card-subtitle>

        <v-card-text>
            <v-btn color="error" @click.stop="disconnect()" class="mx-2" :disabled="!isConnected">
                <v-icon>mdi mdi-power-plug-off-outline</v-icon>
                <span>Disconnect</span>
            </v-btn>
            
            <v-btn color="success" @click.stop="initialize()" class="mx-2" :disabled="isConnected">
                <v-icon>mdi mdi-power-plug-outline</v-icon>
                <span>Connect</span>
            </v-btn>
        </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AdminTools",
  props: [],
  components: {},

  data: () => ({
    message: "",
  }),
  computed: {
    ...mapGetters("MessageHubModule", ["isConnected"])
  },
  methods: {
    ...mapActions("MessageHubModule", ["sendBroadcast", "initialize", "disconnect"]),
    sendTestMessage() {
      this.sendBroadcast(this.message);
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
