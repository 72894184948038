<template>
  <v-card>
    <v-card-text>
      <CRUDContainer
        title="Budget Categories"
        :showEdit="false"
        :showDelete="false"
        :refreshLoading="isLoading"
        :addDisabled="true"
        @add="add()"
        @refresh="getCategories()"
        flat
      >
        <v-data-table
          :headers="headers"
          :items="categories"
          search
          :loading="isLoading"
          disable-pagination
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="px-2 text-left">
                <span v-if="!isEditing(item)">{{ item.name }}</span>
                <v-text-field
                  v-else
                  required
                  v-model="item.name"
                  outlined
                  dense
                  hide-details
                  class="py-2"
                ></v-text-field>
              </td>
              <td class="px-2 text-center">
                <span v-if="!isEditing(item)">{{
                  getTransactionTypeName(item.defaultTransactionTypeId)
                }}</span>
                <v-select
                  v-else
                  v-model="item.defaultTransactionTypeId"
                  :items="transactionTypes"
                  item-value="id"
                  item-text="name"
                  outlined
                  dense
                  hide-details
                  class="py-2"
                ></v-select>
              </td>
              <td class="px-2 text-center">
                <span v-if="!isEditing(item)">
                  {{ item.spendingLimit | currency(0) }}
                </span>

                <v-text-field
                  v-else
                  v-model="item.spendingLimit"
                  type="number"
                  autocomplete="off"
                  prepend-inner-icon="mdi-currency-usd"
                  outlined
                  dense
                  hide-details
                  class="py-2"
                ></v-text-field>
              </td>
              <td class="px-2 text-center">{{ item.sortOrder }}</td>

              <td class="text-right">
                <template v-if="!isEditing(item)">
                  <v-menu close-on-content-click bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item link @click="edit(item)">
                        <v-list-item-title>
                          <v-icon>mdi-pencil</v-icon>
                          <span class="ml-4 vertical-align-middle">Edit</span>
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        link
                        color="#ff0000"
                        @click="setFuelEntryToDelete(item)"
                      >
                        <v-list-item-title>
                          <v-icon color="red">mdi-delete</v-icon>
                          <span class="ml-4 vertical-align-middle red--text">Delete</span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>

                <div v-else>
                  <v-btn icon @click.stop="cancel(item)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-btn icon @click.stop="save(item)">
                    <v-icon>mdi-content-save-outline</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </CRUDContainer>
    </v-card-text>
  </v-card>
</template>

<script>
import CRUDContainer from "@/components/Core/CRUDContainer.vue";
import budgetService from "@/services/budget.service.js";
import categoryService from "@/services/budget-categories.service.js";

export default {
  components: {
    CRUDContainer,
  },
  data: () => ({
    categories: [],
    transactionTypes: [],
    spendingLimits: [],
    isLoadingArray: [],
    editModeCategoryIds: [],
    headers: [
      {
        text: "Category",
        align: "left",
        value: "name",
        showOnSmallScreen: true,
        sortable: false,
      },
      {
        text: "Default Type",
        align: "center",
        value: "defaultTransactionTypeId",
        showOnSmallScreen: true,
        sortable: false,
      },
      {
        text: "Spending Limit",
        align: "center",
        showOnSmallScreen: true,
        sortable: false,
      },
      {
        text: "Display Order",
        align: "center",
        value: "sortOrder",
        showOnSmallScreen: true,
        sortable: false,
      },
      {
        text: "",
        align: "right",
        showOnSmallScreen: true,
        sortable: false,
      },
    ],
  }),
  computed: {
    isSmallScreen() {
      const screenSizes = ["xs", "sm"];
      return screenSizes.indexOf(this.$vuetify.breakpoint.name) >= 0;
    },
    isLoading() {
      return this.isLoadingArray.length > 0;
    },
  },
  methods: {
    async getCategories() {
      try {
        this.isLoadingArray.push(true);
        const result = await budgetService.getBudgetCategories();
        
        this.categories = result.data.sort((a, b) => {
          if (a.sortOrder > b.sortOrder) {
            return 1;
          }
          if (b.sortOrder > a.sortOrder) {
            return -1;
          }
          return 0;
        });
        
        const spendingLimits = (await budgetService.getSpendingLimits()).data;
        console.log(spendingLimits);

        spendingLimits.forEach(spendingLimit => {
          let category = this.categories.find(x => x.id === spendingLimit.budgetCategoryId);
          if (category) {
            category.spendingLimit = spendingLimit.amount;
          }
        })
      } finally {
        this.isLoadingArray.pop();
      }
    },
    async getTransactionTypes() {
      try {
        this.isLoadingArray.push(true);
        const result = await budgetService.getTransactionTypes();
        this.transactionTypes = result.data;
      } finally {
        this.isLoadingArray.pop();
      }
    },
    async getSpendingLimits() {
      try {
        this.isLoadingArray.push(true);
        const result = 
        this.spendingLimits = result.data;
      } finally {
        this.isLoadingArray.pop();
      }
    },
    getTransactionTypeName(id) {
      return this.transactionTypes.find((tt) => tt.id === id).name;
    },
    getCategorySpendingLimit(categoryId) {
      const match = this.spendingLimits.find((sl) => sl.budgetCategoryId === categoryId);
      return match ? match.amount : "";
    },
    edit(categoryItem) {
      this.editModeCategoryIds.push(categoryItem.id);
    },
    cancel(categoryItem) {
      this.editModeCategoryIds = this.editModeCategoryIds.filter(
        (x) => x !== categoryItem.id
      );
    },
    async save(categoryItem) {
      var model = {
        id: categoryItem.id,        
        name: categoryItem.name,
        spendingLimit: categoryItem.spendingLimit,
      }; 
      
      await categoryService.editBudgetCategory(model);
    },
    isEditing(categoryItem) {
      let match = this.editModeCategoryIds.find((x) => x === categoryItem.id);
      return !!match;
    },
  },
  async mounted() {
    await this.getTransactionTypes();
    await this.getCategories();
  },
  watch: {},
};
</script>

<style scoped></style>
