<template>
    <v-card>
    <v-toolbar flat color="primary" dark>
      <v-toolbar-title>Admin</v-toolbar-title>
    </v-toolbar>
    
    <v-tabs>
      <v-tab>
        <v-icon left>mdi-account</v-icon>
        Users
      </v-tab>
      <v-tab>
        <v-icon left>mdi-currency-usd</v-icon>
        Budget
      </v-tab>
      <v-tab>
        <v-icon left>mdi-tools</v-icon>
        Tools
      </v-tab>         

      <v-tab-item>
        <v-card flat class="mb-4">
          <v-card-text>
            <UsersAdmin />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text class="pa-0">
            <BudgetAdmin />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text class="pa-0">
            <AdminTools />
          </v-card-text>
        </v-card>
      </v-tab-item>
      
    </v-tabs>
  </v-card>
</template>

<script>
import UsersAdmin from "@/components/Admin/Users/UsersAdmin.vue";
import BudgetAdmin from "@/components/Admin/Budget/BudgetAdmin.vue";
import AdminTools from "@/components/Admin/Tools/AdminTools.vue";

export default {
    components: {UsersAdmin, BudgetAdmin, AdminTools},
    computed: {
        isSmallScreen() {
            const screenSizes = ['xs','sm'];
            return screenSizes.indexOf(this.$vuetify.breakpoint.name) >= 0;
        },
    },
}
</script>
