<template>
    <v-sheet width="100%" class="d-flex flex-wrap">
        <v-card tile class="users-list flex-grow-1">
            <v-list rounded>
                <v-subheader>Users</v-subheader>
                <v-skeleton-loader v-if="isLoading" type="list-item-avatar, list-item-avatar, list-item-avatar"></v-skeleton-loader>
                <v-list-item-group v-else v-model="selectedUserId" color="primary">
                    <v-list-item v-for="user in users" :key="user.id" :value="user.id">
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{user.firstName}} {{user.lastName}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card> 
        
        <div class="user-settings" v-if="selectedUserId">            
            <v-card tile>
                <v-card-text>
                    <h2>Roles</h2>
                    <v-sheet min-height="100px">             
                        <v-progress-linear v-if="isLoadingUserRoles" indeterminate color="primary"></v-progress-linear>   
                        <template v-else>
                            <v-checkbox v-for="userRole in userRoles" :key="userRole.id" :label="userRole.roleName" @change="updateUserRole" v-model="userRole.isSelected"></v-checkbox>
                        </template>
                    </v-sheet>

                    <template v-if="selectedUserRefreshTokens.length > 0">
                        <v-divider class="mb-4"></v-divider>

                        <h2>Refresh Tokens</h2>
                        <v-sheet min-height="100px">
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th>Issued</th>
                                        <th>Expiration</th>
                                        <th><!-- Actions --></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="token in selectedUserRefreshTokens" :key="token.id">      
                                    <td>{{token.issuedDateTime | shortDateTime}}</td>
                                    <td>{{token.expirationDateTime | shortDateTime}}</td>
                                    <td class="text-right"><v-btn text type="button" color="red" :loading="isRefreshTokenDeleting(token.id)" @click.stop="deleteRefreshToken(token.id)">Delete</v-btn></td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-sheet>
                    </template>
                </v-card-text>
            </v-card>
        </div>
    </v-sheet>
</template>

<script>
import usersService from "@/services/users.service.js";
import userRolesService from "@/services/user-roles.service.js";
import refreshTokensService from "@/services/refresh-token.service.js";

export default {
    name: "UsersAdmin",
    props: [],
    components: {
    }, 

    data: () => ({
        users: [],
        selectedUserId: '',
        userRoles: [],
        refreshTokens: [],
        isLoading: false,
        isLoadingUserRoles: false,
        deletingRefreshTokenIds: [],
    }),
    computed: {
        selectedUserRefreshTokens() {
            return this.refreshTokens.filter(rt => rt.userId === this.selectedUserId);
        }
    },
    methods: {
        async getAllUsers() {
            try {
                this.isLoading = true;
                const resp = await usersService.getAllUsers();
                this.users = resp.data;
            } finally {
                this.isLoading = false;
            }
        },
        async getUserRoles() {
            try {
                this.isLoadingUserRoles = true;
                const resp = await userRolesService.getUserRoles(this.selectedUserId);
                this.userRoles = resp.data;
            } finally {
                this.isLoadingUserRoles = false;
            }

        },
        async updateUserRole() {
            await userRolesService.updateUserRoles(this.selectedUserId, this.userRoles);
            await this.getUserRoles();
        },
        isRefreshTokenDeleting(tokenId) {
            return this.deletingRefreshTokenIds.indexOf(tokenId) >= 0;
        },
        async getRefreshTokens() {
            try {
                this.isLoadingRefreshTokens = true;
                const resp = await refreshTokensService.getRefreshTokens();
                this.refreshTokens = resp.data;
            } finally {
                this.isLoadingRefreshTokens = false;
            }
        },
        async deleteRefreshToken(tokenId) {
            try {
                this.deletingRefreshTokenIds.push(tokenId);
                await refreshTokensService.deleteRefreshToken(tokenId);
                await this.getRefreshTokens();
            } finally {
                const index = this.deletingRefreshTokenIds.indexOf(tokenId);
                if (index >= 0) {
                    this.deletingRefreshTokenIds.splice(index, 1);
                }
            }
        },
    },
    mounted() {
        this.getAllUsers();
        this.getRefreshTokens();
    },
    watch: {
        selectedUserId() {
            this.userRoles = [];
            this.getUserRoles();
        }
    }
}
</script>

<style scoped>
    .users-list {
        flex-basis: 260px;
    }
    .user-settings {
        flex-basis: 260px;
        flex-grow: 8;
        flex-shrink: 1;
    }
</style>
