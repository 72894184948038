<template>
    <v-sheet>
        <v-card>
            <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    :disabled="isLoading || recurringTransactions.length === 0"
                ></v-text-field>
                <v-spacer />
                <v-btn fab small @click.stop="addRecurringTransaction()" color="primary" dark>
                    <v-icon dark>mdi-plus</v-icon>
                </v-btn>
            </v-card-title>     

            <v-card-text class="pa-0">        
                <v-data-table
                    :headers="displayedHeaders"
                    :items="recurringTransactions"
                    :search="search"
                    :loading="isLoading"
                    disable-pagination
                    hide-default-footer
                    :hide-default-header="isSmallScreen"
                    :show-expand="isSmallScreen"
                    :single-expand="true"
                    :expanded.sync="expandedRows"
                    :custom-sort="customSort"
                    group-by="budgetCategory.name"
                    class="elevation-1"
                    mobile-breakpoint="0"
                    fixed-header
                >
                    <template v-slot:[`group.header`]="{items}">
                        <th colspan="100%" class="text-center">
                            <span class="group-name">{{ items[0].budgetCategory.name }}</span>                            
                        </th>
                    </template>

                    <template v-slot:item="{ item }">
                        <tr>
                            <template v-if="isSmallScreen">                                
                                <td class="px-1 text-center small-text">{{getRecurranceText(item)}}</td>
                                <td class="px-1 text-center small-text">
                                    <div>{{item.startDate | monthDay}}</div>
                                    <div class="small-text">{{item.startDate | year}}</div>                                   
                                </td>
                                <td class="px-1 small-text">{{item.description}}</td>
                                <td class="pl-1 pr-0 text-center">                                    
                                    <v-tooltip right v-if="item.isAutopay">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="mx-1" color="primary" v-bind="attrs" v-on="on" small>mdi-currency-usd</v-icon>                      
                                        </template>
                                        <span>Autopay</span>
                                    </v-tooltip>                                    
                                </td>
                                <td class="px-1 text-right small-text">{{item.amount | currency}}</td>
                            </template>
                            <template v-else>                               
                                <td class="px-2 text-center">{{getRecurranceText(item)}}</td>
                                <td class="px-2 text-center">{{item.startDate | shortDate}}</td>
                                <td class="px-2">{{item.description}}</td>
                                <td class="px-2">{{item.memo}}</td>
                                <td class="px-2 text-center">
                                    <v-tooltip right v-if="item.isAutopay">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon  class="mx-1" color="primary" v-bind="attrs" v-on="on" small>mdi-currency-usd</v-icon>                      
                                        </template>
                                        <span>Autopay</span>
                                    </v-tooltip>                                    
                                </td>
                                <td class="px-2 text-right">{{item.amount | currency}}</td>
                                <td class="px-2 text-center">{{item.transactionType.name}}</td>
                            </template>

                            <td class="text-right">                        
                                <v-menu close-on-content-click bottom left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on">
                                            <v-icon>mdi-dots-horizontal</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item @click="editRecurringRansaction(item)">
                                            <v-list-item-title>
                                                <v-icon>mdi-pencil</v-icon>
                                                <span class="ml-4 vertical-align-middle">Edit</span>
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item link color="#ff0000" @click="confirmDeleteRecurringTransaction(item)">
                                            <v-list-item-title>
                                                <v-icon color="red">mdi-delete</v-icon>
                                                <span class="ml-4 vertical-align-middle red--text">Delete</span>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                    </template>                    
                </v-data-table>                
            </v-card-text>
        </v-card>
        
        <RecurringTransactionFormDialog v-model="selectedRecurringTransaction" @saved="onSaved()" />
        <ConfirmDeleteRecurringTransactionDialog v-model="recurringTransactionToDelete" @deleted="getRecurringTransactions()"/>
    </v-sheet>
</template>


<script>
import {mapState} from "vuex";
import recurringTransactionsService from "@/services/recurring-transaction.service.js";
import RecurringTransactionFormDialog from "@/components/Admin/Budget/RecurringTransactionFormDialog.vue";
import ConfirmDeleteRecurringTransactionDialog from "@/components/Admin/Budget/ConfirmDeleteRecurringTransactionDialog.vue";
import moment from "moment";

export default {
    name: "RecurringTransactions",
    props: {        
    },
    components: {
        RecurringTransactionFormDialog,
        ConfirmDeleteRecurringTransactionDialog
    }, 

    data: () => ({
        headers: [
            { text: 'Recurs', align: 'center', value: 'getRecurranceText(item)', sortable: false, groupable: false, showOnSmallScreen: true},
            { text: 'Start Date', align: 'center', value: 'startDate', showOnSmallScreen: true},
            { text: 'Description', value: 'description', groupable: false, showOnSmallScreen: true },
            { text: 'Memo', value: 'memo', sortable: false, groupable: false, showOnSmallScreen: false },
            { text: 'Autopay', align: 'center', value: 'isAutopay', sortable: false, groupable: false, showOnSmallScreen: true},
            { text: 'Amount', value: 'amount', align: 'right', groupable: false, showOnSmallScreen: true },
            { text: 'Debit/Credit', value: 'transactionType', align: 'center', showOnSmallScreen: false },
            { text: '', sortable: false, groupable: false, showOnSmallScreen: true}              
        ],            
        search: '',
        recurringTransactions: [],
        isLoadingArray: [],
        recurringTransactionToDelete: '',
        expandedRows: [],
        selectedRecurringTransaction: '',
        isSaving: false,
        valid: true,
    }),
    computed: {
        ...mapState("BudgetModule", {
            budgetCategories: "budgetCategories",
            transactionTypes: "transactionTypes"            
        }),
        isSmallScreen() {
            const screenSizes = ['xs','sm'];
            return screenSizes.indexOf(this.$vuetify.breakpoint.name) >= 0;
        },
        isLoading() {
            return this.isLoadingArray.length > 0;
        },
        displayedHeaders() {
            return this.headers.filter(h => !this.isSmallScreen || h.showOnSmallScreen);
        }

    },
    methods: {
        customSort: function(items, index, isDesc) {
            if (index.length > 1) {
                items.sort((a, b) => {
                    const sortColumn = index[1];
                    if (sortColumn) {
                        if (sortColumn === 'startDate') {
                            const aDate = moment(a.startDate).format('M/DD');
                            const bDate = moment(b.startDate).format('M/DD');
                            const aParts = aDate.split('/');
                            const bParts = bDate.split('/');
                            const aVal = aParts[aParts.length - 1];
                            const bVal = bParts[bParts.length - 1];
                            if (!isDesc[0]) {
                                return bVal-aVal;
                            } else {
                                return aVal-bVal;
                            }
                        } else {
                            if(a[sortColumn]){
                                if (!isDesc[1]) {
                                    return a[sortColumn].toLowerCase().localeCompare(b[sortColumn].toLowerCase());
                                }
                                else {
                                    return b[sortColumn].toLowerCase().localeCompare(a[sortColumn].toLowerCase());
                                }
                            }
                        }
                    }                
                });
            }
            
            items.sort((a,b) => {
                let fieldParts = index[0];
                fieldParts = fieldParts.split('.');

                let aVal = a;
                let bVal = b;
                fieldParts.forEach(p => {
                    aVal = aVal[p];
                    bVal = bVal[p];
                })
                aVal = aVal.toLowerCase();
                bVal = bVal.toLowerCase();

                if (!isDesc[0]) {
                    return aVal.localeCompare(bVal);
                }
                else {
                    return bVal.localeCompare(aVal);
                }
            });

            return items;
        },
        async getRecurringTransactions() {
             try {
                this.isLoadingArray.push(true);
                const resp = await recurringTransactionsService.getRecurringTransactions();
                this.recurringTransactions = resp.data;
            } finally {
                this.isLoadingArray.pop();
            } 
                
        },
        getRecurranceText(recurringTransaction) {
            switch (recurringTransaction.recurranceTypeId) {
                case 1: 
                    return recurringTransaction.recurranceNumber === 1 
                        ? 'Monthly' 
                        : `${recurringTransaction.recurranceNumber} Months`;
                case 2: 
                    return recurringTransaction.recurranceNumber === 1 
                        ? 'Daily'
                        : `${recurringTransaction.recurranceNumber} Days`;    
            }                            
        },
        
        addRecurringTransaction() {
            this.selectedRecurringTransaction = {
                id: null,
                description: '',
                memo: null,
                amount: null,
                budgetCategoryId: 2, // Default = Bills
                transactionTypeId: 1, // Default = Debit
                recurranceTypeId: 1, // Default = Monthly
                recurranceNumber: 1,
                startDate: new Date(),
                isActive: true,

                budgetCategory: null,
                transactionType: null,
            };            
        },
        editRecurringRansaction(transaction) {
            this.selectedRecurringTransaction = {...transaction};
        },        
        confirmDeleteRecurringTransaction(rc) {
            this.recurringTransactionToDelete = {...rc};
        },
        onSaved() {
            this.getRecurringTransactions();
        }
    },
    async mounted() {
        await this.getRecurringTransactions();       
    }
}
</script>

<style scoped>
    .group-name {
        font-size: 1.4rem;
    }   
    .small-text {
       font-size: .8em;
    } 
</style>