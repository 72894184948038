<template>
  <v-dialog :value="value" width="400" @keydown.esc="cancel()">
        <v-card>
          <v-form @submit.prevent="confirmed">
            <v-card-title class="headline red lighten-3">Confirm</v-card-title>

            <v-card-text class="pt-2">
              <div class="body-1">Are you sure you want to delete this recurring transaction?</div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn type="button" text @click="close()" :disabled="loading">Cancel</v-btn>

                <v-spacer></v-spacer>

                <v-btn type="submit" color="red" text :loading="loading">Delete</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
</template>

<script>
import recurringTransactionsService from "@/services/recurring-transaction.service.js";
import toasterHandler from "@/utils/toaster.handler.js";

export default {
  name: "ConfirmDeleteRecurringTransaction",

  props: ["value"],

  components: {},

  data: () => ({
    loading: false,
  }),

  computed: {    
    isSmallScreen() {
      const screenSizes = ['xs','sm'];
      return screenSizes.indexOf(this.$vuetify.breakpoint.name) >= 0;
    }
  },
  methods: {   
    close() {
      this.$emit("input", '');
    },
    async confirmed() {
      try {
        this.loading = true;
        await recurringTransactionsService.deleteRecurringTransaction(this.value);
        toasterHandler.showToast('success', 'mdi-trash-outline', null, "Recurring transaction deleted", null, null, null);              
        this.$emit("deleted");
        this.close();
      } finally {
        this.loading = false;
      }
    },
  }    
};
</script>
