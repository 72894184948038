<template>
    <v-sheet width="100%" class="d-flex flex-wrap">
        <v-card tile class="menu flex-grow-1" :loading="isLoading">
            <v-list rounded>
                <v-list-item-group v-model="selectedPage" color="primary">
                <v-list-item value="budgetCategories">
                    <v-list-item-content>
                        <v-list-item-title>Budget Categories</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                
                <v-list-item value="recurringTransactions">
                    <v-list-item-content>
                        <v-list-item-title>Recurring Transactions</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
            </v-list>
        </v-card> 
        
        <div class="selected-component pa-2">            
            <RecurringTransactions v-if="selectedPage ==='recurringTransactions'" />
            <BudgetCategories v-if="selectedPage === 'budgetCategories'" />
        </div>
    </v-sheet>
</template>

<script>
import RecurringTransactions from "@/components/Admin/Budget/RecurringTransactions.vue";
import BudgetCategories from "@/components/Admin/Budget/BudgetCategories.vue";
import {mapActions} from "vuex";
export default {
    name: "BudgetAdmin",
    props: [],
    components: {
        RecurringTransactions,
        BudgetCategories
    }, 

    data: () => ({
        selectedPage: '',
        isLoadingArray: [],
    }),
    computed: {        
        isLoading() {
            return this.isLoadingArray.length > 0;
        },
        isSmallScreen() {
            const screenSizes = ['xs','sm'];
            return screenSizes.indexOf(this.$vuetify.breakpoint.name) >= 0;
        },
    },
    methods: {
        ...mapActions("BudgetModule",["setTransactionTypes", "setBudgetCategories"]),
    },
    mounted() {
        this.setTransactionTypes();
        this.setBudgetCategories();
    }
}
</script>


<style scoped>
    .menu {
        flex-basis: 260px;
    }
    .selected-component {
        flex-basis: 260px;
        flex-grow: 8;
        flex-shrink: 1;
    }
</style>